export const downloadFile = (url, name) => {
    window.fetch(url)
      .then(res => {
        if (res.status === 200) {
          return res.blob()
        }
        return false
      }, (error) => {
        console.log(error)
      })
      .then(
        blob => {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style.display = 'none'
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob)
          a.href = url
          // 指定下载的文件名
          a.download = `${name}`
          a.click()
          document.body.removeChild(a)
          // 移除blob对象的url
          window.URL.revokeObjectURL(url)
        },
      )
  }
  
  